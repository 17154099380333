<template>
  <div class="remarks container-fluid">
    <div class="row m-0 align-items-center h-100">
      <div class="title col p-0">{{ label }}</div>
      <font-awesome-icon
        v-if="!chapter"
        @click="action()"
        class="fa-plus"
        icon="plus"
      />
    </div>
    <hr />
    <div
      v-if="(!list || !list.length) && noDataLabel.length > 0"
      class="row m-0 align-items-center h-100"
    >
      {{ noDataLabel }}
    </div>
    <template v-if="chapter">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="row m-0 align-items-center h-100"
      >
        <router-link :to="getLinkToModule(item._id)">
          {{ item.title }}
        </router-link>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(item, index) in list"
        :key="index"
        class="row m-0 align-items-center h-100"
      >
        <div class="title col p-0 my-3">{{ item.title }}</div>
        <font-awesome-icon @click="action(item._id)" icon="edit" />
      </div>
    </template>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

library.add(faPlus, faEdit);

import "bootstrap/dist/css/bootstrap.min.css";
export default {
  name: "LabelPanel",
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: "Remarks",
    },
    list: {
      type: Array,
      default: () => [],
    },
    chapter: {
      type: Boolean,
      default: false,
    },
    noDataLabel: {
      type: String,
      default: "",
    },
  },
  methods: {
    getLinkToModule(id) {
      return this.$router.resolve({
        name: "bundle-module",
        params: {
          monetization_bundle_id: this.$route.params.monetization_bundle_id,
          module_id: id,
        },
      }).href;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.remarks {
  width: 290px;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 1px 1px 10px 0px #b6b6b6;
  padding: 10px calc(30px - 12px) 10px 25px;

  @media (max-width: 1199px) {
    width: auto;
  }

  @media (min-width: 1200px) {
    padding: 15px (40px - 24px) 15px 25px;
  }
  a {
    color: #2c3e50;
  }
  .row {
    .fa-plus {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #efefef;
      padding: 3px;

      &:hover {
        cursor: pointer;
      }
    }

    .title {
      @media (min-width: 1600px) {
        font-size: 18px;
      }
    }
  }
}
</style>
