<template>
  <BaseCard :styles="{ borderRadius: '15px', padding: '0' }" class="blank-card">
    <!-- <div class="card"> -->
    <div class="blank-card__content">
      <h4>Partnercontent</h4>
      <p>-soon-</p>
      <div class="soon">Demnächst</div>
    </div>
    <div class="blank-card__footer"></div>
    <!-- </div> -->
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard.vue";
export default {
  name: "BlankCard",
  components: { BaseCard },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.blank-card {
  position: relative;

  &__content {
    background: gray;
    border-radius: 10px 10px 0 0;
    padding: 20px 15px 10px;
    height: calc(100% - 40px);
    min-height: 180px;
    hyphens: auto;
    width: 100%;
    box-shadow: 2px 0px 23px -6px #00000026;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;

    & h4 {
      color: $white;
      font-size: 14px;
      font-weight: 600;
      white-space: normal;
      word-wrap: break-word;
      font-family: "Oswald", sans-serif;
      line-height: 1.2;
    }

    & p {
      color: $white;
      margin-bottom: 0;
      margin: 0 0 10px;
      font-size: 12px;
      white-space: normal;
      word-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
      padding: 0;
    }

    & .soon {
      right: 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #00000024;
      text-align: center;
      color: $white;
      font-weight: 600;
      padding: 10px;
      font-size: 16px;
    }
  }

  &__footer {
    display: block;
    height: 40px;
    background: gray;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
</style>
